import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import { DownloadCloud } from "react-feather";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { saveAs } from "file-saver";
import Loader from "@src/components/shared/Loader";
import { RadioInput } from "@src/components/inputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import LoadingButton from "@src/components/shared/LoadingButton";
import PermissionButton from "@src/components/shared/PermissionButton";

const ExportComponent = ({
  id,
  exportMessageFunction,
  messageStatus,
  exportFileName = `Message.txt`,
  messageType,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const exportOptions = [
    { label: "Message Only", value: 0 },
    { label: "Ack Only", value: 1 },
    { label: "Message with Ack", value: 2 },
  ];

  const exportMessageSchema = yup.object().shape({
    exportOption: yup.string().required(),
  });

  useEffect(() => {
    if (modalVisible) {
      formMethods.reset({
        exportOption: 0,
      });
    }
  }, [modalVisible, formMethods]);

  const formMethods = useForm({
    resolver: yupResolver(exportMessageSchema),
    mode: "onSubmit",
    defaultValues: {
      exportOption: 0,
    },
  });

  const handleExport = async () => {
    ConfirmSweetAlert({
      title: "Are you sure you need to export this message?",
      icon: "question",
      confirmButtonText: "Export",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        setIsExporting(true);
        const response = await exportMessageFunction({
          id,
          exportOption: 0,
        })
          .unwrap()
          .then(() => {
            const blob = new Blob([response], { type: "text/plain" });
            const fileName = exportFileName;

            saveAs(blob, fileName);
            SuccessToast("Message exported Successfully!");
            setIsExporting(false);
          })
          .catch((rejected) => {});
      },
    });
  };

  const handleExportClick = () => {
    if (messageStatus == "sent") {
      setModalVisible(true);
    } else {
      handleExport();
    }
  };

  const handleModalExport = async (formData) => {
    setIsExporting(true);
    const response = await exportMessageFunction({
      id: id,
      exportOption: formData.exportOption,
    })
      .unwrap()
      .then(() => {
        const blob = new Blob([response], { type: "text/plain" });
        saveAs(blob, exportFileName);
        SuccessToast("Message exported Successfully!");
        setIsExporting(false);
        setModalVisible(false);
      })
      .catch((rejected) => {});
  };

  return (
    <>
      {isExporting && <Loader />}
      <PermissionButton
        outline
        type="button"
        onClick={handleExportClick}
        color="secondary"
        disabled={isExporting}
        permission={`sim.${messageType}.view`}
      >
        <DownloadCloud size={18} />
        &nbsp; Export
      </PermissionButton>
      <Modal
        isOpen={modalVisible}
        toggle={() => setModalVisible(false)}
        className="p-fluid"
      >
        <FormProvider {...formMethods}>
          <Form onSubmit={formMethods.handleSubmit(handleModalExport)}>
            <ModalHeader toggle={() => setModalVisible(false)}>
              Export Message
            </ModalHeader>
            <ModalBody>
              <RadioInput
                twoLines
                label="Export Option"
                name="exportOption"
                options={exportOptions}
                optionKey="value"
                optionLabel="label"
                horizontalAlignment={true}
              />
            </ModalBody>
            <ModalFooter>
              <LoadingButton
                type="submit"
                loading={isExporting}
                color="primary"
              >
                Export
              </LoadingButton>
              <Button
                type="button"
                color="warning"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default ExportComponent;
