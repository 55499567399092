import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import {
  useGetPrlMessagesQuery,
  useBulkResendMessagesMutation,
} from "@src/redux/messages";
import prlMessagesColumns from "./Columns";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import BulkResendMessagesModel from "@src/components/Messages/BulkResendMessagesModel";
import Loader from "@src/components/shared/Loader";
import { Send } from "react-feather";
import ErrorModal from "./ErrorModal";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import PermissionButton from "@src/components/shared/PermissionButton";

const PrlMessages = () => {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [selectedErrors, setSelectedErrors] = useState([]);
  const [visible, setVisible] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    "messageTime.date": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    partNo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "flight.flightNo": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "flight.departure": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "flight.arrival": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);

  const { data, isLoading, isFetching } = useGetPrlMessagesQuery(filters);
  const [bulkResendMessages, { isLoading: loadingResendMessages, error }] =
    useBulkResendMessagesMutation();

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const actionTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <PermissionButton
          color="secondary"
          className="me-1"
          onClick={() => setVisible(true)}
          permission="sim.prl.send"
        >
          <Send size={18} />
          &nbsp; Bulk Resend
        </PermissionButton>
      </div>
    );
  };

  const toggleErrorModal = () => setIsErrorModalOpen(!isErrorModalOpen);

  const showErrors = (part) => {
    setSelectedErrors(part.messageErrors);
    toggleErrorModal();
  };
  return (
    <>
      {loadingResendMessages && <Loader />}
      <BreadCrumbs pageName="prlList" pageTitle="PRL Message List" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={prlMessagesColumns(showErrors)}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No PRL Messages Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            actionTemplate={actionTemplate}
            tableState={filters}
          />
        </CardBody>
      </Card>
      <BulkResendMessagesModel
        visible={visible}
        toggle={() => setVisible(false)}
        bulkResendMessagesFunc={bulkResendMessages}
        messageType={"prl"}
      />
      <ErrorModal
        isOpen={isErrorModalOpen}
        toggle={toggleErrorModal}
        errors={selectedErrors}
      />
    </>
  );
};

export default PrlMessages;
