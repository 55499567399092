import React from "react";
import { Button } from "reactstrap";
import { useUser } from "@app/layout";

function PermissionButton({ permission, ...props }) {
  const { hasPermission } = useUser();

  if (!permission)
    return <Button.Ripple {...props}>{props.children}</Button.Ripple>;
  return hasPermission(permission) ? (
    <Button.Ripple {...props}>{props.children}</Button.Ripple>
  ) : null;
}

export default PermissionButton;
