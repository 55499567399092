import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const breadcrumbMap = {
  home: [],
  flightList: [{ label: "Flights", active: true }],
  flightDetails: [
    {
      label: "Flights",
      path: "/sita-messages/flights",
    },
    { label: "Details", active: true },
  ],
  prlList: [{ label: "PRL-Messages", active: true }],
  prlDetails: [
    {
      label: "PRL-Messages",
      path: "/sita-messages/prl-messages",
    },
    { label: "Details", active: true },
  ],
  ldmList: [{ label: "LDM-Messages", active: true }],
  ldmDetails: [
    {
      label: "LDM-Messages",
      path: "/sita-messages/ldm-messages",
    },
    { label: "Details", active: true },
  ],
  apisList: [{ label: "APIS-Messages", active: true }],
  apisDetails: [
    {
      label: "APIS-Messages",
      path: "/sita-messages/apis-messages",
    },
    { label: "Details", active: true },
  ],
  loadSheetList: [{ label: "Load-Sheets", active: true }],
  loadSheetDetails: [
    {
      label: "Load-Sheets",
      path: "/sita-messages/load-sheets",
    },
    { label: "Details", active: true },
  ],
  loadSheetUpload: [
    {
      label: "Load-Sheets",
      path: "/sita-messages/load-sheets",
    },
    { label: "Upload", active: true },
  ],
  stationMailsList: [{ label: "Station-Mails", active: true }],
  messageTemplateList: [{ label: "Message-Templates", active: true }],
  messageTemplateDetails: [
    {
      label: "Message-Templates",
      path: "/sita-messages/messageTemplates",
    },
    { label: "Details", active: true },
  ],
  messageTemplateEdit: [
    {
      label: "Message-Templates",
      path: "/sita-messages/messageTemplates",
    },
    { label: "Edit", active: true },
  ],
  messageSenderList: [{ label: "Message-Senders", active: true }],
  apisSettingsList: [{ label: "Apis-Settings", active: true }],
  messageAddressesList: [{ label: "Message-Addresses", active: true }],
  pnlMessageGeneratorList: [{ label: "PNL Message-Generator", active: true }],
};

const BreadCrumbs = ({ pageName, pageTitle }) => {
  const items = breadcrumbMap[pageName] || [];

  const breadcrumbItems = [
    { label: "Home", path: "/sita-messages/home" },
    ...items,
  ];

  return (
    <div className="content-header row">
      <div className="content-header-left col-md-9 col-12 mb-2">
        <div className="row breadcrumbs-top">
          <div className="col-12">
            {pageTitle && (
              <h3 className="content-header-title float-start mb-0 ms-1">
                {pageTitle}
              </h3>
            )}
            <div className="breadcrumb-wrapper vs-breadcrumbs d-sm-block d-none col-12">
              <Breadcrumb>
                {breadcrumbItems.map((item, index) => (
                  <BreadcrumbItem
                    key={index}
                    active={item.active}
                    className={item.active ? "text-muted" : ""}
                  >
                    {item.path ? (
                      <Link to={item.path}>{item.label}</Link>
                    ) : (
                      item.label
                    )}
                  </BreadcrumbItem>
                ))}
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BreadCrumbs.propTypes = {
  pageName: PropTypes.string.isRequired,
  pageTitle: PropTypes.string,
};

export default BreadCrumbs;
