import React from "react";
import { Button } from "primereact/button";
import { Download } from "react-feather";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { saveAs } from "file-saver";
import Loader from "@src/components/shared/Loader";
import { useLazyGetBulkMessageFileQuery } from "@src/redux/pnlMessageGenerators";

const DownloadBulkFileComponent = ({ fileIdentifier }) => {
  const [getBulkMessageFile, { isLoading }] = useLazyGetBulkMessageFileQuery();

  const handleExport = async () => {
    const response = await getBulkMessageFile(fileIdentifier).unwrap();
    const blob = new Blob([response], { type: response.type });
    saveAs(blob, fileIdentifier);
    SuccessToast("File downloded Successfully!");
  };

  return (
    <>
      {isLoading && <Loader />}
      <Button
        tooltip="Download"
        tooltipOptions={{ position: "top" }}
        text
        onClick={handleExport}
      >
        <Download className="font-medium-3 text-body" />
      </Button>
    </>
  );
};

export default DownloadBulkFileComponent;
