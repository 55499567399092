import PnlMessageGenerators from ".";

const PnlMessageGeneratorsRoutes = [
  {
    path: "/pnl-message-generators",
    index: true,
    element: <PnlMessageGenerators />,
  },
];

export default PnlMessageGeneratorsRoutes;
