import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import {
  useGetApisSettingsQuery,
  useDeleteApisSettingMutation,
} from "@src/redux/ApisSettings";
import apisSettingsColumns from "./Columns";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import ApisSettingModal from "./ApisSettingModal";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";

const ApisSettings = () => {
  const [editData, setEditData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState("add");
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    country: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    recipientCode: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);

  const { data, isLoading } = useGetApisSettingsQuery(filters);
  const [deleteApisSetting, { isLoading: loadingDeleteApisSetting }] =
    useDeleteApisSettingMutation();

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const handleAddClick = () => {
    setEditData(null);
    setMode("add");
    setVisible(true);
  };

  const handleEditClick = (item) => {
    setEditData(item);
    setMode("update");
    setVisible(true);
  };

  const handleDeleteClick = async (item) => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to Delete this apis setting?",
      icon: "question",
      confirmButtonText: "Delete",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await deleteApisSetting(item.id)
          .unwrap()
          .then(() => {
            SuccessToast("Apis setting deleted Successfully!");
          })
          .catch((rejected) => {});
      },
    });
  };

  return (
    <>
      <BreadCrumbs pageName="apisSettingsList" pageTitle="APIS Setting List" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={apisSettingsColumns(handleEditClick, handleDeleteClick)}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Apis Settings Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            displayAdd={true}
            handleAdd={handleAddClick}
            tableState={filters}
            addPermission="sim.apis-settings.add"
          />
        </CardBody>
        <ApisSettingModal
          visible={visible}
          toggle={() => setVisible(false)}
          editData={editData}
          setEditData={setEditData}
          mode={mode}
        />
      </Card>
    </>
  );
};

export default ApisSettings;
