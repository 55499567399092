import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  CardSubtitle,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlane,
  faPlaneDeparture,
  faPlaneArrival,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { formatDateTime } from "@src/utility/Utils";
import PermissionLink from "@src/components/shared/PermissionLink";

const MessageFlightDetails = ({ data }) => {
  return (
    <Card>
      <CardHeader className="border-bottom">
        <CardTitle tag="h1" className="fw-bolder">
          <strong>
            <span className="text-primary">
              <FontAwesomeIcon icon={faPlane} />
            </span>{" "}
            <span className="text-primary">Flight</span>
            {"  "}
            <span className="fw-bolder mb-25"> XY{data.flightNo} </span>{" "}
            <span className="font-small-2 text-muted">Flight No</span>
          </strong>
        </CardTitle>
        {data?.flightId && (
          <CardSubtitle>
            <span className="text-primary">
              <FontAwesomeIcon icon={faEye} />{" "}
              <PermissionLink
                to={`/sita-messages/flights/${data?.flightId}`}
                title={`Flight details`}
                permission="sim.flights.view"
              >
                Flight Details
              </PermissionLink>
            </span>
          </CardSubtitle>
        )}
      </CardHeader>
      <CardBody>
        <div className="info-container">
          <br />
          <Row>
            <div className="info-container">
              <Row>
                <Col xl="6" xs="12">
                  <Row tag="dl" className="mb-0">
                    <Col tag="dt" sm="4" className="fw-bolder mb-1">
                      Departure:
                    </Col>
                    <Col tag="dd" sm="7" className="mb-1">
                      <span className="text-primary">
                        &nbsp;
                        <FontAwesomeIcon icon={faPlaneDeparture} />
                      </span>{" "}
                      {data.departure}
                    </Col>

                    <Col tag="dt" sm="4" className="fw-bolder mb-1">
                      STD:
                    </Col>
                    <Col tag="dd" sm="7" className="mb-1">
                      {data.std && formatDateTime(new Date(data.std))}
                    </Col>

                    <Col tag="dt" sm="4" className="fw-bolder mb-1">
                      STD <span className="font-small-2 text-muted">(UTC)</span>
                      :
                    </Col>
                    <Col tag="dd" sm="7" className="mb-1">
                      {data.stdutc && formatDateTime(new Date(data.stdutc))}
                    </Col>
                  </Row>
                </Col>
                <Col xl="6" xs="12" className="mb-1">
                  <Row tag="dl" className="mb-0">
                    <Col tag="dt" sm="4" className="fw-bolder mb-1">
                      Arrival:
                    </Col>
                    <Col tag="dd" sm="7" className="mb-1">
                      <span className="text-primary">
                        &nbsp;
                        <FontAwesomeIcon icon={faPlaneArrival} />
                      </span>{" "}
                      {data.arrival}
                    </Col>

                    <Col tag="dt" sm="4" className="fw-bolder mb-1">
                      STA:
                    </Col>
                    <Col tag="dd" sm="7" className="mb-1">
                      {data.sta && formatDateTime(new Date(data.sta))}
                    </Col>
                    <Col tag="dt" sm="4" className="fw-bolder mb-1">
                      STA <span className="font-small-2 text-muted">(UTC)</span>
                      :
                    </Col>
                    <Col tag="dd" sm="7" className="mb-1">
                      {data.stautc && formatDateTime(new Date(data.stautc))}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default MessageFlightDetails;
