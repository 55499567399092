import React from "react";
import { Link } from "react-router-dom";
import { useUser } from "@app/layout";

function PermissionLink({ permission, ...props }) {
  const { hasPermission } = useUser();

  if (!permission) return <Link {...props}>{props.children}</Link>;
  return hasPermission(permission) ? (
    <Link {...props}>{props.children}</Link>
  ) : null;
}

export default PermissionLink;
