import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import {
  useGetLdmMessagesQuery,
  useBulkResendMessagesMutation,
} from "@src/redux/messages";
import { ldmMessagesColumns } from "./Columns";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import BulkResendMessagesModel from "@src/components/Messages/BulkResendMessagesModel";
import Loader from "@src/components/shared/Loader";
import { Send } from "react-feather";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { getMessageInitAdvancedFilter } from "@src/utility/Utils";
import PermissionButton from "@src/components/shared/PermissionButton";

const LdmMessages = () => {
  const [bulkResendMessages, { isLoading: loadingResendMessages, error }] =
    useBulkResendMessagesMutation();
  const [visible, setVisible] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: getMessageInitAdvancedFilter(),
    keyword: "",
  });

  const initialDtFilters = {
    "messageTime.date": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    "flight.flightNo": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "flight.departure": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "flight.arrival": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);

  const { data, isLoading } = useGetLdmMessagesQuery(filters);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: getMessageInitAdvancedFilter(),
      keyword: "",
    });
  };

  const actionTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <PermissionButton
          color="secondary"
          className="me-1"
          onClick={() => setVisible(true)}
          permission="sim.ldm.send"
        >
          <Send size={18} />
          &nbsp; Bulk Resend
        </PermissionButton>
      </div>
    );
  };

  return (
    <>
      {loadingResendMessages && <Loader />}
      <BreadCrumbs pageName="ldmList" pageTitle="LDM Message List" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={ldmMessagesColumns}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No LDM Messages Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            actionTemplate={actionTemplate}
            tableState={filters}
          />
        </CardBody>
      </Card>
      <BulkResendMessagesModel
        visible={visible}
        toggle={() => setVisible(false)}
        bulkResendMessagesFunc={bulkResendMessages}
        messageType={"ldm"}
        sendingMethods={["sita", "email"]}
      />
    </>
  );
};

export default LdmMessages;
