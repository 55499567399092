// ** React Imports
// import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

// ** Store & Actions
// import { useSelector, useDispatch } from "react-redux";
// import { handleMenuHidden, handleContentWidth } from "@store/layout";

// ** Third Party Components
import classnames from "classnames";
import { ArrowUp } from "react-feather";

// ** Reactstrap Imports
import { Navbar, NavItem, Button } from "reactstrap";

// ** Configs
import themeConfig from "@configs/themeConfig";

// ** Custom Components

import Customizer from "@components/customizer";
import ScrollToTop from "@components/scrolltop";
import NavbarComponent from "./components/navbar";
import FooterComponent from "./components/footer";
import MenuComponent from "./components/menu/horizontal-menu";

// ** Custom Hooks
import { useRTL } from "@hooks/useRTL";
import { useSkin } from "@hooks/useSkin";
import { useLayout } from "@hooks/useLayout";
import { useNavbarType } from "@hooks/useNavbarType";
import { useFooterType } from "@hooks/useFooterType";
import { useNavbarColor } from "@hooks/useNavbarColor";

// ** Styles
import "@styles/base/core/menu/menu-types/horizontal-menu.scss";

// const appTheme={
//   "skin": "light",
//   "isRTL": false,
//   "layout": "horizontal",
//   "lastLayout": "horizontal",
//   "menuCollapsed": false,
//   "footerType": "sticky",
//   "navbarType": "hidden",
//   "menuHidden": true,
//   "contentWidth": "full",
//   "navbarColor": "white"
// }

const handleMenuHidden = (v) => {
  console.log("handleMenuHidden", v);
};
const handleContentWidth = (v) => {
  console.log("handleContentWidth", v);
};

const HorizontalLayout = (props) => {
  const setMenuVisibility = (v) => {
    console.log("setMenuVisibility", v, appTheme);
  };
  // ** Props
  const { navbar, menuData, footer, children, menu, reactDom, appTheme } =
    props;
  const { Link } = reactDom || {};
  // ** Hooks
  const { skin, setSkin } = useSkin(appTheme);
  const [isRtl, setIsRtl] = useRTL();
  const { navbarType, setNavbarType } = useNavbarType(appTheme);
  const { footerType, setFooterType } = useFooterType(appTheme);
  const { navbarColor, setNavbarColor } = useNavbarColor(appTheme);
  const { layout, setLayout, setLastLayout } = useLayout(appTheme || {});

  // ** States
  const [isMounted, setIsMounted] = useState(false);
  const [navbarScrolled, setNavbarScrolled] = useState(false);

  // ** Store Vars
  // const dispatch = useDispatch();
  // const layoutStore = useSelector((state) => state.layout);

  // ** Vars
  const contentWidth = appTheme.contentWidth;
  const isHidden = appTheme.menuHidden;

  // ** Handles Content Width
  // const setContentWidth = (val) => dispatch(handleContentWidth(val));
  const setContentWidth = (val) => handleContentWidth(val);

  // ** Handles Content Width
  // const setIsHidden = (val) => dispatch(handleMenuHidden(val));
  const setIsHidden = (val) => handleMenuHidden(val);

  // ** UseEffect Cleanup
  const cleanup = () => {
    setIsMounted(false);
    setNavbarScrolled(false);
  };

  //** ComponentDidMount
  useEffect(() => {
    setIsMounted(true);
    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 65 && navbarScrolled === false) {
        setNavbarScrolled(true);
      }
      if (window.pageYOffset < 65) {
        setNavbarScrolled(false);
      }
    });
    return () => cleanup();
  }, []);

  // ** Vars
  const footerClasses = {
    static: "footer-static",
    sticky: "footer-fixed",
    hidden: "footer-hidden",
  };

  const navbarWrapperClasses = {
    floating: "navbar-floating",
    sticky: "navbar-sticky",
    static: "navbar-static",
  };

  const navbarClasses = {
    floating:
      contentWidth === "boxed" ? "floating-nav container-xxl" : "floating-nav",
    sticky: "fixed-top",
  };

  const bgColorCondition =
    navbarColor !== "" && navbarColor !== "light" && navbarColor !== "white";

  if (!isMounted) {
    return null;
  }

  return (
    <div
      className={classnames(
        `wrapper horizontal-layout horizontal-menu ${
          navbarWrapperClasses[navbarType] || "navbar-floating"
        } ${footerClasses[footerType] || "footer-static"} menu-expanded`
      )}
      {...(isHidden ? { "data-col": "1-column" } : {})}
    >
      <Navbar
        expand="lg"
        container={false}
        className={classnames(
          "header-navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center",
          {
            "navbar-scrolled": navbarScrolled,
          }
        )}
      >
        {/* <div className="d-flex"> */}

        {!navbar && (
          <div className="navbar-header d-xl-block d-none">
            <ul className="nav navbar-nav">
              <NavItem>
                <Link to="/" className="navbar-brand">
                  <span className="brand-logo">
                    <img src={themeConfig.app.appLogoImage} alt="logo" />
                  </span>
                  <h2 className="brand-text mb-0">{themeConfig.app.appName}</h2>
                </Link>
              </NavItem>
            </ul>
          </div>
        )}

        <Navbar
          tag="div"
          expand="sm"
          light={skin !== "dark"}
          dark={skin === "dark" || bgColorCondition}
          className={classnames(
            `header-navbar navbar-horizontal navbar-container w-100 d-flex content`
            // {
            //   [navbarClasses[navbarType]]: navbarType !== "static",
            //   "floating-nav":
            //     (!navbarClasses[navbarType] && navbarType !== "static") ||
            //     navbarType === "floating",
            // }
          )}
          style={{ maxHeight: "70px" }}
        >
          {menu ? (
            menu({ menuData, routerProps, currentActiveItem })
          ) : (
            <MenuComponent menuData={menuData} {...props} />
          )}
        </Navbar>
        {/* </div> */}

        <div
          className="navbar-container d-flex content"
          style={{ maxWidth: "250px" }}
        >
          {navbar ? (
            navbar({ skin, setSkin })
          ) : (
            <NavbarComponent
              skin={skin}
              setSkin={setSkin}
              setMenuVisibility={setMenuVisibility}
              {...props}
            />
          )}
        </div>
      </Navbar>
      {/* {!isHidden ? (
        <div className="horizontal-menu-wrapper">
          <Navbar
            tag="div"
            expand="sm"
            light={skin !== "dark"}
            dark={skin === "dark" || bgColorCondition}
            className={classnames(
              `header-navbar navbar-horizontal navbar-shadow menu-border`,
              {
                [navbarClasses[navbarType]]: navbarType !== "static",
                "floating-nav":
                  (!navbarClasses[navbarType] && navbarType !== "static") ||
                  navbarType === "floating",
              }
            )}
          >
            {menu ? (
              menu({ menuData, routerProps, currentActiveItem })
            ) : (
              <MenuComponent menuData={menuData} {...props} />
            )}
          </Navbar>
        </div>
      ) : null} */}

      {children}
      {themeConfig.layout.customizer === true ? (
        <Customizer
          skin={skin}
          isRtl={isRtl}
          layout={layout}
          setSkin={setSkin}
          setIsRtl={setIsRtl}
          isHidden={isHidden}
          setLayout={setLayout}
          footerType={footerType}
          navbarType={navbarType}
          setIsHidden={setIsHidden}
          themeConfig={themeConfig}
          navbarColor={navbarColor}
          contentWidth={contentWidth}
          setFooterType={setFooterType}
          setNavbarType={setNavbarType}
          setLastLayout={setLastLayout}
          setNavbarColor={setNavbarColor}
          setContentWidth={setContentWidth}
        />
      ) : null}
      <footer
        className={classnames(
          `footer footer-light ${footerClasses[footerType] || "footer-static"}`,
          {
            "d-none": footerType === "hidden",
          }
        )}
      >
        {footer ? (
          footer
        ) : (
          <FooterComponent
            footerType={footerType}
            footerClasses={footerClasses}
          />
        )}
      </footer>

      {themeConfig.layout.scrollTop === true ? (
        <div className="scroll-to-top">
          <ScrollToTop showOffset={300} className="scroll-top d-block">
            <Button className="btn-icon" color="primary">
              <ArrowUp size={14} />
            </Button>
          </ScrollToTop>
        </div>
      ) : null}
    </div>
  );
};
export default HorizontalLayout;
