import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import { faCalendar, faJetFighterUp } from "@fortawesome/free-solid-svg-icons";
import { TextInput, CalendarInput, SelectInput } from "@src/components/inputs";
import LoadingButton from "@src/components/shared/LoadingButton";
import { useGenerateBulkMessagesMutation } from "@src/redux/pnlMessageGenerators";

const GenerateBulkMessagesModel = ({ visible, toggle }) => {
  const [generateBulkMessages, { isLoading }] =
    useGenerateBulkMessagesMutation();

  const generateBulkSchema = yup.object().shape({
    start: yup
      .date()
      .typeError("Start date is required")
      .required("Start date is required"),
    end: yup
      .date()
      .typeError("End date is required")
      .required("End date is required")
      .min(yup.ref("start"), "End date can't be before start date"),
    departure: yup.string(),
    arrival: yup.string(),
    flightType: yup
      .string()
      .oneOf(["domestic", "international", ""])
      .nullable(),
    flightNo: yup
      .number()
      .typeError("Flight No must be a number")
      .nullable()
      .moreThan(0, "Flight No can not be negative")
      .transform((_, val) => (val !== "" ? Number(val) : null)),
  });

  const formMethods = useForm({
    resolver: yupResolver(generateBulkSchema),
    mode: "onSubmit",
    defaultValues: {
      start: "",
      end: "",
      departure: "",
      arrival: "",
      flightType: "",
      flightNo: "",
    },
  });

  useEffect(() => {
    if (visible) {
      formMethods.reset({
        start: "",
        end: "",
        departure: "",
        arrival: "",
        flightType: "",
        flightNo: "",
      });
    }
  }, [visible, formMethods]);

  const handleFormSubmit = async (formData) => {
    await generateBulkMessages({
      start: formData.start,
      end: formData.end,
      departure: formData.departure || undefined,
      arrival: formData.arrival || undefined,
      flightType: formData.flightType || undefined,
      flightNo: formData.flightNo,
    }).unwrap();

    SuccessToast("Messages generated successfully!");
    toggle(false);
  };

  const flightTypeOptions = [
    { label: "Domestic", value: "domestic" },
    { label: "International", value: "international" },
  ];

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid">
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
          <ModalHeader toggle={toggle}>Generate Bulk PNL Messages</ModalHeader>
          <ModalBody>
            <div className="mb-2">
              <CalendarInput
                label="Start Time"
                name="start"
                icon={faCalendar}
                options={{
                  enableTime: true,
                }}
              />
            </div>
            <div className="mb-2">
              <CalendarInput
                label="End Time"
                name="end"
                icon={faCalendar}
                options={{
                  enableTime: true,
                }}
              />
            </div>
            <div className="mb-2">
              <TextInput label="Departure" name="departure" />
            </div>
            <div className="mb-2">
              <TextInput label="Arrival" name="arrival" />
            </div>
            <div className="mb-2">
              <SelectInput
                label="Flight Type"
                name="flightType"
                options={flightTypeOptions}
                placeholder="(Optional) Select flight type"
                isClearable
              />
            </div>
            <div className="mb-2">
              <TextInput
                label="FlightNo"
                name="flightNo"
                icon={faJetFighterUp}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <LoadingButton loading={isLoading} type="submit" color="primary">
              Generate
            </LoadingButton>
            <Button type="button" color="warning" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default GenerateBulkMessagesModel;
