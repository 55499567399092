import Table from "@src/components/DataTable/Table";
import { Card, CardBody, Button, ButtonGroup } from "reactstrap";
import { flightsColumns } from "./Columns";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DownloadCloud, Save, File } from "react-feather";
import FetchFlightsModel from "./FetchFlightsModel";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import ConfirmSweetAlert from "@src/components/shared/ConfirmSweetAlert";
import PermissionButton from "@src/components/shared/PermissionButton";
import {
  useGetFlightsQuery,
  useUpdateIcaoStationsMutation,
  useFetchFlightsMutation,
  useExportFlightsMutation,
} from "@src/redux/flights";
import Loader from "@src/components/shared/Loader";
import { saveAs } from "file-saver";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import { useLazyGetFlightManifestQuery } from "@src/redux/flights";

const Flights = () => {
  const [visible, setVisible] = useState(false);
  const [updateIcaoStations, { isLoading: loadingUpdateIcaoStations }] =
    useUpdateIcaoStationsMutation();
  const [exportFlights] = useExportFlightsMutation();
  const [fetchFlights, { isLoading: loadingFetchFlights }] =
    useFetchFlightsMutation();
  const [getFlightManifest] = useLazyGetFlightManifestQuery();

  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    flightNo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    departure: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    arrival: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    registration: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "stdutc.date": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    inboundPassengersCount: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    onwardPassengersCount: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);

  const { data, isLoading } = useGetFlightsQuery(filters);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };
  const actionTemplate = () => {
    return (
      <ButtonGroup>
        <PermissionButton
          color="secondary"
          onClick={handleUpdateIcao}
          permission="sim.flights.update"
        >
          <Save size={18} />
          &nbsp; Update Icao
        </PermissionButton>
        <PermissionButton
          color="secondary"
          outline
          onClick={() => setVisible(true)}
          permission="sim.flights.fetch"
        >
          <DownloadCloud size={18} />
          &nbsp; Fetch
        </PermissionButton>
        <PermissionButton
          color="secondary"
          onClick={handleExport}
          permission="sim.flights.view"
        >
          <File size={18} />
          &nbsp; Export
        </PermissionButton>
      </ButtonGroup>
    );
  };

  const handleUpdateIcao = async () => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to update flights ICAO stations",
      icon: "question",
      confirmButtonText: "Update",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        await updateIcaoStations()
          .unwrap()
          .then(() => {
            SuccessToast("ICAO Stations Updated Successfully!");
          })
          .catch((rejected) => {});
      },
    });
  };

  const handleExport = async () => {
    ConfirmSweetAlert({
      title: "Are you sure , you need to export flights?",
      text: "If no filtration was applied, flights of this month will be exported.",
      icon: "question",
      confirmButtonText: "Export",
      showCancelButton: true,
      cancelButtonClasses: "btn btn-warning ms-1",
      cancelButtonText: "Cancel",
      onConfirm: async () => {
        let fetchFilters = { ...filters };
        fetchFilters.advancedFilter ??= getAdvancedFilters();
        const response = await exportFlights(fetchFilters)
          .unwrap()
          .then(() => {
            const blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const fileName = `exported_flights_${new Date()
              .toISOString()
              .replace(/:/g, "-")}.xlsx`;

            saveAs(blob, fileName);
            SuccessToast("Flights exported Successfully!");
          })
          .catch((rejected) => {});
      },
    });
  };

  const getAdvancedFilters = () => {
    const today = new Date();
    let end = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    let start;

    if (today.getDate() === 1) {
      start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      end = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of previous month
    } else {
      start = new Date(today.getFullYear(), today.getMonth(), 1);
    }

    return {
      logic: "or",
      filters: [
        {
          logic: "and",
          filters: [
            {
              field: "stdutc.date",
              operator: "lt",
              value: end,
            },
            {
              field: "stdutc.date",
              operator: "gt",
              value: start,
            },
          ],
        },
      ],
    };
  };

  return (
    <>
      {(loadingUpdateIcaoStations || loadingFetchFlights) && <Loader />}
      <BreadCrumbs pageName="flightList" pageTitle="Flight List" />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={flightsColumns(getFlightManifest)}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No Flights Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            actionTemplate={actionTemplate}
            tableState={filters}
          />
        </CardBody>
      </Card>
      <FetchFlightsModel
        visible={visible}
        toggle={() => setVisible(false)}
        fetchFlightsFunc={fetchFlights}
      />
    </>
  );
};

export default Flights;
