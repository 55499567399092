import Table from "@src/components/DataTable/Table";
import { Card, CardBody } from "reactstrap";
import { useGetPnlMessageGeneratorsQuery } from "@src/redux/pnlMessageGenerators";
import PnlMessageGeneratorColumns from "./Columns";
import { useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import GenerateBulkMessagesModel from "./GenerateBulkMessagesModel";
import { Send } from "react-feather";
import PermissionButton from "@src/components/shared/PermissionButton";

const PnlMessageGeneratorSearch = () => {
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [],
    advancedFilter: null,
    keyword: "",
  });

  const initialDtFilters = {
    "start.date": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    "end.date": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    departure: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    arrival: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    flightType: { value: null, matchMode: FilterMatchMode.EQUALS },
    flightNo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "createdOnUtc.date": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    createdBy: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  const [dtFilters, setDtFilters] = useState(initialDtFilters);

  const { data, isLoading } = useGetPnlMessageGeneratorsQuery(filters);

  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);

  const clearFilters = () => {
    setDtFilters(initialDtFilters);
    setFilters({
      pageNumber: 1,
      pageSize: 10,
      orderBy: [],
      advancedFilter: null,
      keyword: "",
    });
  };

  const actionTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <PermissionButton
          color="secondary"
          className="me-1"
          onClick={() => setIsGenerateModalOpen(true)}
          permission="sim.pnl.generate"
        >
          <Send size={18} />
          &nbsp; Generate Bulk Messages
        </PermissionButton>
      </div>
    );
  };

  return (
    <>
      <BreadCrumbs
        pageName="pnlMessageGeneratorList"
        pageTitle="Pnl Message-Generator List"
      />
      <Card>
        <CardBody>
          <Table
            metaData={{
              ...data?.metadata,
              loading: isLoading,
            }}
            columns={PnlMessageGeneratorColumns}
            values={data?.items || []}
            dataKey="id"
            emptyMessage="No PNL Message Generators Found..."
            filters={dtFilters}
            setTableState={setFilters}
            clearFilter={clearFilters}
            tableState={filters}
            actionTemplate={actionTemplate}
          />
        </CardBody>
      </Card>
      <GenerateBulkMessagesModel
        visible={isGenerateModalOpen}
        toggle={() => setIsGenerateModalOpen(!isGenerateModalOpen)}
      />
    </>
  );
};

export default PnlMessageGeneratorSearch;
