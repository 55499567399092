import React, { Fragment, memo, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Label,
  InputGroup,
} from "reactstrap";
import {
  useGetApisMessageQuery,
  useExportApisMessageMutation,
} from "@src/redux/messages";
import { useParams } from "react-router-dom";
import { ReadonlyInput } from "@src/components/inputs";
import Loader from "@src/components/shared/Loader";
import {
  faFontAwesome,
  faCalendar,
  faMessage,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateTime } from "@src/utility/Utils";
import StatusTag from "../../components/Messages/StatusTag";
import CopyToClipboard from "@src/components/shared/CopyToClipboard";
import ResendMessageModel from "../common/ResendMessageModel";
import MessageHistoryModel from "../common/MessageHistoryModel";
import { Send } from "react-feather";
import BreadCrumbs from "@src/components/shared/BreadCrumbs";
import ExportComponent from "../common/ExportComponent";
import MessageFlightDetails from "../common/MessageFlightDetails";
import PermissionButton from "@src/components/shared/PermissionButton";

const getDirectionIcon = (direction) => {
  switch (direction) {
    case "inbound":
      return faArrowRightToBracket;
    case "outbound":
      return faArrowRightFromBracket;
  }
};

const Details = () => {
  const [currentItem, setCurrentItem] = useState(null);
  const [exportMessage] = useExportApisMessageMutation();
  const [showDetails, setShowDetails] = useState(false);
  const [visible, setVisible] = useState(false);
  const { id } = useParams();
  const { data, isFetching, error } = useGetApisMessageQuery(id);

  const handleShowDetails = (item) => {
    setCurrentItem(item);
    setShowDetails(true);
  };

  return (
    <Fragment>
      {isFetching && <Loader />}
      <BreadCrumbs pageName="apisDetails" pageTitle="APIS Details" />
      {data && (
        <>
          <MessageFlightDetails data={data} />
          <Card>
            <CardHeader className="border-bottom">
              <CardTitle tag="h1" className="fw-bolder">
                <strong>
                  <span className="text-primary">
                    <FontAwesomeIcon icon={faUserTie} />
                  </span>{" "}
                  <span className="text-primary">APIS</span>
                  {"  "}
                  <span class="fw-bolder mb-25">Message Details</span>
                </strong>
              </CardTitle>
              <div
                className="flex flex-wrap gap-2"
                style={{
                  justifyContent: "right",
                  display: "flex",
                }}
              >
                <ExportComponent
                  id={data.id}
                  exportMessageFunction={exportMessage}
                  messageStatus={data.status}
                  exportFileName={`XY${data.flightNo}_APIS_Message.txt`}
                />
                <PermissionButton
                  type="button"
                  onClick={() => {
                    setVisible(true);
                  }}
                  color="secondary"
                  permission="sim.apis.send"
                >
                  <Send size={18} />
                  &nbsp; Resend
                </PermissionButton>
              </div>
            </CardHeader>
            <CardBody>
              <div className="info-container">
                <br />
                <Row>
                  <Col lg="4" md="4" className="mb-1">
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Message Time"
                        name="messageTime"
                        icon={faCalendar}
                        defaultValue={
                          data.messageTime &&
                          formatDateTime(new Date(data.messageTime))
                        }
                      />
                    </div>
                  </Col>
                  <Col lg="4" md="4" className="mb-1">
                    <div className="mb-1">
                      <Label className="form-label" for="flightDirection">
                        <h6>Direction</h6>
                      </Label>
                      <InputGroup>
                        <span className="p-inputgroup-addon text-primary">
                          <FontAwesomeIcon icon={faFontAwesome} />
                        </span>
                        <span
                          className="form-control input-group text-capitalize"
                          style={{ backgroundColor: "#efefef", opacity: 1 }}
                        >
                          {data.flightDirection && (
                            <FontAwesomeIcon
                              icon={getDirectionIcon(data.flightDirection)}
                              className="font-medium-3"
                            />
                          )}{" "}
                          <span style={{ paddingLeft: "5px" }}>
                            {data.flightDirection}
                          </span>
                        </span>
                      </InputGroup>
                    </div>
                  </Col>
                  <Col lg="3" md="3" className="mb-1">
                    <div className="mb-1">
                      <Label className="form-label" for="messageStatus">
                        <h6>Message Status</h6>
                      </Label>
                      <InputGroup>
                        <StatusTag status={data.status} />
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={data.status === "sent" ? "6" : "12"}
                    md={data.status === "sent" ? "6" : "12"}
                    className="mb-1"
                  >
                    <div className="mb-1">
                      <ReadonlyInput
                        label="Message Content"
                        name="MessageContent"
                        icon={faMessage}
                        type="textarea"
                        rows="15"
                        defaultValue={data.text}
                      />
                      <CopyToClipboard
                        value={data.text}
                        defaultMsg="Click to copy"
                        successMsg="Copied to clipboard!"
                      />
                    </div>
                  </Col>
                  <Col lg="6" md="6" className="mb-1">
                    <div className="mb-1">
                      {data.status === "sent" && (
                        <div id="ack">
                          <ReadonlyInput
                            label="Ack"
                            name="Ack"
                            icon={faMessage}
                            type="textarea"
                            rows="15"
                            defaultValue={data.ack}
                          />
                          <CopyToClipboard
                            value={data.ack}
                            defaultMsg="Click to copy"
                            successMsg="Copied to clipboard!"
                          ></CopyToClipboard>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs="12">
                    <h5 class="mb-1">Message History</h5>
                    {data.messageHistories?.length > 0 ? (
                      <Table bordered responsive className="mb-2">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th className="text-capitalize">Message Time</th>
                            <th className="text-capitalize">Sending Mode</th>
                            <th className="text-capitalize">Status</th>
                            <th className="text-capitalize">Address Types</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.messageHistories.map((item, index) => {
                            const primaryAddress = item.historyAddresses.find(
                              (address) => address.primary
                            );
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {item.messageTime &&
                                    formatDateTime(new Date(item.messageTime))}
                                </td>
                                <td className="text-capitalize">
                                  {item.sendingMode}
                                </td>
                                <td>
                                  <StatusTag status={primaryAddress?.status} />
                                </td>
                                <td>
                                  {[
                                    ...new Set(
                                      item.historyAddresses.map(
                                        (address) => address.addressType
                                      )
                                    ),
                                  ].join(", ")}
                                </td>
                                <td>
                                  <div className="fw-bolder text-success">
                                    <IconButton
                                      icon={
                                        <Eye
                                          className="font-medium-3 text-body"
                                          title="Show"
                                          style={{ marginLeft: 10 }}
                                        />
                                      }
                                      tooltip={"Show details"}
                                      tooltipOptions={{ position: "left" }}
                                      onClick={() => handleShowDetails(item)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    ) : (
                      <Alert color="dark">
                        <div className="alert-body mt-1">
                          <AlertCircle size={14} />
                          <span className="align-middle ms-50">
                            No history found for this message.
                          </span>
                        </div>
                      </Alert>
                    )}
                  </Col>
                </Row> */}
              </div>
            </CardBody>
          </Card>
          <ResendMessageModel
            visible={visible}
            toggle={() => setVisible(false)}
            messageId={data.id}
            messageType="apis"
          />
        </>
      )}
      {currentItem && (
        <MessageHistoryModel
          visible={showDetails}
          toggle={() => setShowDetails(false)}
          history={currentItem}
        />
      )}
    </Fragment>
  );
};

export default memo(Details);
