import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "primereact/dropdown";
import { formatDateTime, dateFilterTemplate } from "@src/utility/Utils";
import { faGlobe, faHome } from "@fortawesome/free-solid-svg-icons";
import DownloadBulkFileComponent from "./DownloadBulkFileComponent";
import SendBulkEmailComponent from "./SendBulkEmailComponent";

const PnlMessageGeneratorColumns = () => {
  const flightTypes = ["Domestic", "International"];

  const getFlightTypeIcon = (type) => {
    switch (type) {
      case "domestic":
        return faHome;
      case "international":
        return faGlobe;
      default:
        return null;
    }
  };

  const flightTypeItemTemplate = (option) => (
    <span className="text-capitalize">
      <FontAwesomeIcon
        icon={getFlightTypeIcon(option)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {option}
    </span>
  );

  const flightTypeBodyTemplate = (rowData) => (
    <span className="text-capitalize">
      <FontAwesomeIcon
        icon={getFlightTypeIcon(rowData.flightType)}
        style={{ fontSize: "1.3rem" }}
      />{" "}
      {rowData.flightType}
    </span>
  );

  const flightTypeFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={flightTypes}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={flightTypeItemTemplate}
      placeholder="Select One"
      className="p-column-filter"
      showClear
    />
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="d-flex align-items-center gap-2">
        <DownloadBulkFileComponent fileIdentifier={rowData.fileIdentifier} />
        <SendBulkEmailComponent fileIdentifier={rowData.fileIdentifier} />
      </div>
    );
  };

  return [
    {
      header: "Start Time",
      field: "start",
      filter: true,
      sortable: false,
      dataType: "date",
      filterField: "start.date",
      body: (rowData) => formatDateTime(new Date(rowData.start)),
      filterElement: (rowData) => dateFilterTemplate(rowData),
    },
    {
      header: "End Time",
      field: "end",
      filter: true,
      sortable: false,
      dataType: "date",
      filterField: "end.date",
      body: (rowData) => formatDateTime(new Date(rowData.end)),
      filterElement: (rowData) => dateFilterTemplate(rowData),
    },
    {
      header: "Departure",
      field: "departure",
      filter: true,
    },
    {
      header: "Arrival",
      field: "arrival",
      filter: true,
    },
    {
      header: "Flight Type",
      field: "flightType",
      filter: true,
      body: flightTypeBodyTemplate,
      filterElement: flightTypeFilterTemplate,
      showFilterMatchModes: false,
    },
    {
      header: "Flight No",
      field: "flightNo",
      filter: true,
      dataType: "numeric",
      sortable: false,
    },
    {
      header: (
        <>
          Created On<span className="font-small-2 text-muted"> (UTC)</span>
        </>
      ),
      field: "createdOnUtc",
      filter: true,
      sortable: false,
      dataType: "date",
      body: (rowData) => formatDateTime(new Date(rowData.createdOnUtc)),
      filterElement: (rowData) => dateFilterTemplate(rowData),
      filterField: "createdOnUtc.date",
    },
    {
      header: "Created By",
      field: "createdBy",
      filter: true,
    },
    {
      header: "Actions",
      field: "action",
      filter: false,
      body: actionBodyTemplate,
    },
  ];
};

export default PnlMessageGeneratorColumns;
