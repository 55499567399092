import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useUser } from "@app/layout";

const appUrl = process.env.REACT_APP_API_URL;

// initialize an empty api service that we'll inject endpoints into later as needed
export const mainApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: appUrl,
    prepareHeaders: async (headers) => {
      const { getUserToken } = useUser();
      const token = await getUserToken();
      headers.set("Authorization", `Bearer ${token}`);
    },
  }),
  endpoints: () => ({}),
});
