import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Table,
} from "reactstrap";
import ReadonlyInput from "@src/components/inputs/ReadonlyInput";
import CopyToClipboard from "@src/components/shared/CopyToClipboard";
import { faMessage, faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatusTag from "../../components/Messages/StatusTag";

const MessageHistoryModel = ({ visible, toggle, history, partNo }) => {
  const renderAddresses = (addresses) => {
    const addressArray = addresses.split(";");
    if (addressArray.length > 5) {
      return `${addressArray.slice(0, 5).join(", ")}... (${
        addressArray.length - 5
      } more)`;
    }
    return addresses;
  };

  return (
    <Modal isOpen={visible} toggle={toggle} className="p-fluid modal-xl">
      <ModalHeader toggle={toggle}>
        Message History Addresses{" "}
        {partNo ? (
          <>
            {" "}
            <span className="text-primary">
              <FontAwesomeIcon icon={faPuzzlePiece} />
            </span>{" "}
            Part <span className="text-primary fw-bolder">{partNo}</span>
          </>
        ) : (
          <></>
        )}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col
            lg={history?.ack ? "6" : "12"}
            md={history?.ack ? "6" : "12"}
            className="mb-1"
          >
            <div className="mb-1">
              <ReadonlyInput
                label="Message Content"
                name="MessageContent"
                icon={faMessage}
                type="textarea"
                rows="15"
                defaultValue={history.text}
              />
              <CopyToClipboard
                value={history.text}
                defaultMsg="Click to copy"
                successMsg="Copied to clipboard!"
              />
            </div>
          </Col>
          {history?.ack && (
            <Col lg="6" md="6" className="mb-1">
              <div className="mb-1">
                <div id="ack">
                  <ReadonlyInput
                    label="Ack"
                    name="Ack"
                    icon={faMessage}
                    type="textarea"
                    rows="15"
                    defaultValue={history.ack}
                  />
                  <CopyToClipboard
                    value={history.ack}
                    defaultMsg="Click to copy"
                    successMsg="Copied to clipboard!"
                  />
                </div>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Table responsive>
            <thead>
              <tr>
                <th className="text-capitalize">Sending Method</th>
                <th className="text-capitalize">Address</th>
                <th className="text-capitalize">Address Type</th>
                <th className="text-capitalize">Status</th>
              </tr>
            </thead>
            <tbody>
              {history?.historyAddresses
                .filter((item) => item.address)
                .map((item, index) => (
                  <tr key={index}>
                    <td>{item.sendingMethod}</td>
                    <td>{renderAddresses(item.address)}</td>
                    <td>{item.addressType}</td>
                    <td>
                      <StatusTag status={item.status} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="warning" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MessageHistoryModel;
