import { mainApi } from "./mainApi";

export const messagesApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getPrlMessages: builder.query({
      query: (filters) => ({
        url: `/messages/search/prl`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "PrlMessage", id: "Search" }],
    }),
    getLdmMessages: builder.query({
      query: (filters) => ({
        url: `/messages/search/ldm`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "LdmMessage", id: "Search" }],
    }),
    getApisMessages: builder.query({
      query: (filters) => ({
        url: `/messages/search/apis`,
        method: "POST",
        body: filters,
      }),
      transformResponse: (response, meta, arg) => {
        const { data, ...metadata } = response;
        return { items: data, metadata };
      },
      providesTags: (result) => [{ type: "ApisMessage", id: "Search" }],
    }),
    getLdmMessage: builder.query({
      query: (id) => ({
        url: `/messages/ldm/${id}`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: "LdmMessage", id: "Details" }],
    }),
    getApisMessage: builder.query({
      query: (id) => ({
        url: `/messages/apis/${id}`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: "ApisMessage", id: "Details" }],
    }),
    getPrlMessage: builder.query({
      query: (flightId) => ({
        url: `/messages/prl/${flightId}`,
        method: "GET",
      }),
      providesTags: (result) => [{ type: "PrlMessage", id: "Details" }],
    }),
    resendLdmMessage: builder.mutation({
      query: (data) => ({
        url: `/messages/ldm/${data.id}/resend`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "LdmMessage", id: "Details" }],
    }),
    resendApisMessage: builder.mutation({
      query: (data) => ({
        url: `/messages/apis/${data.id}/resend`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "ApisMessage", id: "Details" }],
    }),
    resendPrlMessage: builder.mutation({
      query: (data) => ({
        url: `/messages/prl/resend-message`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "PrlMessage", id: "Details" }],
    }),
    bulkResendMessages: builder.mutation({
      query: (data) => ({
        url: `/messages/resend`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        { type: "PrlMessage", id: "Search" },
        { type: "LdmMessage", id: "Search" },
        { type: "ApisMessage", id: "Search" },
      ],
    }),
    exportPrlMessage: builder.mutation({
      query: (data) => ({
        url: `/messages/prl/${data.id}/export/${data.exportOption}`,
        method: "POST",
        responseHandler: (response) => response.blob(),
      }),
    }),
    exportLdmMessage: builder.mutation({
      query: (data) => ({
        url: `/messages/ldm/${data.id}/export/${data.exportOption}`,
        method: "POST",
        responseHandler: (response) => response.blob(),
      }),
    }),
    exportApisMessage: builder.mutation({
      query: (data) => ({
        url: `/messages/apis/${data.id}/export/${data.exportOption}`,
        method: "POST",
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPrlMessagesQuery,
  useGetLdmMessagesQuery,
  useGetApisMessagesQuery,
  useGetLdmMessageQuery,
  useGetApisMessageQuery,
  useGetPrlMessageQuery,
  useResendLdmMessageMutation,
  useResendApisMessageMutation,
  useResendPrlMessageMutation,
  useExportPrlMessageMutation,
  useBulkResendMessagesMutation,
  useExportLdmMessageMutation,
  useExportApisMessageMutation,
} = messagesApi;
