import { Calendar } from "primereact/calendar";
import { Eye, Download } from "react-feather";
import PermissionLink from "@src/components/shared/PermissionLink";
import { format } from "date-fns";
import PermissionIconButton from "@src/components/shared/PermissionIconButton";
import { SuccessToast } from "@src/components/shared/SuccessToast";

const formatDate = (value) => {
  return value.toLocaleDateString("en-UK", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

const stdutcBodyTemplate = (rowData) => {
  return formatDate(new Date(rowData.stdutc));
};

const stdutcFilterTemplate = (options) => {
  return (
    <Calendar
      value={options.value ? new Date(options.value) : null}
      onChange={(e) => {
        options.filterCallback(format(e.value, "yyyy-MM-dd"), options.index);
      }}
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      mask="99/99/9999"
    />
  );
};

export const flightsColumns = (getFlightManifest, isDownloadingManifest) => {
  const handleDownloadManifest = async (id, flightno) => {
    const response = await getFlightManifest(id).unwrap();
    const blob = new Blob([response], { type: response.type });
    saveAs(blob, `flight_manifest_${flightno}.pdf`);
    SuccessToast("Flight manifest downloded Successfully!");
  };

  const actionsBodyTemplate = (row) => {
    return (
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <PermissionLink
          to={`/sita-messages/flights/${row.id}`}
          permission="sim.flights.view"
        >
          {" "}
          <Eye className="font-medium-3 text-body" />
        </PermissionLink>
        <PermissionIconButton
          icon={<Download className="font-medium-3 text-body" />}
          tooltip="Download Manifest"
          tooltipOptions={{ position: "top" }}
          onClick={() => handleDownloadManifest(row.id, row.flightNo)}
          permission="sim.flights.manifest"
        ></PermissionIconButton>
      </div>
    );
  };

  return [
    {
      header: "Flight No",
      filter: true,
      field: "flightNo",
      sortable: false,
      dataType: "numeric",
    },
    {
      header: "Departure",
      filter: true,
      field: "departure",
      sortable: false,
    },
    {
      header: "Arrival",
      filter: true,
      field: "arrival",
      sortable: false,
    },
    {
      header: "Registration",
      filter: true,
      field: "registration",
      sortable: false,
    },
    {
      header: "STDUTC",
      filter: true,
      field: "stdutc",
      sortable: false,
      dataType: "date",
      body: stdutcBodyTemplate,
      filterElement: stdutcFilterTemplate,
      filterField: "stdutc.date",
    },
    {
      header: "Onward Pax",
      field: "onwardPassengersCount",
      sortable: false,
      dataType: "numeric",
    },
    {
      header: "Inbound Pax",
      field: "inboundPassengersCount",
      sortable: false,
      dataType: "numeric",
    },
    {
      header: "Actions",
      field: "action",
      filter: false,
      body: actionsBodyTemplate,
    },
  ];
};
