import React, { useState } from "react";
import { Send } from "react-feather";
import { SuccessToast } from "@src/components/shared/SuccessToast";
import Loader from "@src/components/shared/Loader";
import { useSendBulkMessagesMutation } from "@src/redux/pnlMessageGenerators";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
} from "reactstrap";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RadioInput, ChipsInput } from "@src/components/inputs";
import PermissionButton from "@src/components/shared/PermissionButton";
import PermissionIconButton from "@src/components/shared/PermissionIconButton";

const SendBulkEmailComponent = ({ fileIdentifier }) => {
  const [sendBulkMessages, { isLoading }] = useSendBulkMessagesMutation();
  const [modalVisible, setModalVisible] = useState(false);

  const recipientOptions = [
    { label: "Send to predefined addresses", value: "System" },
    { label: "Send to specific addresses", value: "ExtraEmails" },
  ];

  const sendBulkSchema = yup.object().shape({
    options: yup.string().required("Recipient option is required"),
    extraEmails: yup.array().when("options", {
      is: (val) => val === "ExtraEmails",
      then: (schema) =>
        schema
          .of(yup.string().email("Must be a valid email"))
          .min(1, "At least one email is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const formMethods = useForm({
    resolver: yupResolver(sendBulkSchema),
    mode: "onChange",
    defaultValues: {
      options: "System",
      extraEmails: [],
    },
  });

  const options = useWatch({
    control: formMethods.control,
    name: "options",
  });

  const showExtraEmails = options === "ExtraEmails";

  const toggleModal = () => {
    setModalVisible(!modalVisible);
    if (!modalVisible) {
      formMethods.reset();
    }
  };

  const handleSend = async (formData) => {
    const payload = {
      identifier: fileIdentifier,
      options: formData.options,
      extraEmails: showExtraEmails ? formData.extraEmails.join(";") : null,
    };

    await sendBulkMessages(payload).unwrap();
    SuccessToast("Messages sent Successfully!");
    toggleModal();
  };

  return (
    <>
      {isLoading && <Loader />}

      <PermissionIconButton
        icon={<Send className="font-medium-3 text-body" />}
        tooltip="Send by Email"
        tooltipOptions={{ position: "top" }}
        onClick={toggleModal}
        permission="sim.pnl.send"
      />

      <Modal
        isOpen={modalVisible}
        toggle={toggleModal}
        className="modal-dialog-centered"
      >
        <FormProvider {...formMethods}>
          <Form onSubmit={formMethods.handleSubmit(handleSend)}>
            <ModalHeader toggle={toggleModal}>Send Bulk Messages</ModalHeader>
            <ModalBody>
              <div className="mb-2">
                <RadioInput
                  twoLines
                  name="options"
                  options={recipientOptions}
                  optionKey="value"
                  optionLabel="label"
                />
              </div>
              {showExtraEmails && (
                <div className="mb-2">
                  <ChipsInput
                    name="extraEmails"
                    label="Extra Emails"
                    placeholder="Enter email and press enter"
                  />
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <PermissionButton
                loading={isLoading}
                type="submit"
                color="primary"
                permission="sim.pnl.send"
              >
                Save
              </PermissionButton>
              <Button type="button" color="warning" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default SendBulkEmailComponent;
