import React from "react";
import IconButton from "@src/components/shared/IconButton";
import { useUser } from "@app/layout";

function PermissionIconButton({ permission, ...props }) {
  const { hasPermission } = useUser();

  if (!permission) return <IconButton {...props} />;
  return hasPermission(permission) ? <IconButton {...props} /> : null;
}

export default PermissionIconButton;
